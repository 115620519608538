<template>
  <base-layout>
    <ion-content>
      <div style="margin-top: 400px; text-align: center" v-if="this.isConsultant==false"> You are not registered as a Consultant </div>
    <div  v-if="this.isConsultant==true" id="" class=" meeting-sessionGridComponent meeting-has-header {{ !disableChange ? 'has-subheader' : ''}}" v-bind:class="roomsOnHorizontal ? 'rooms-horizontal' : 'rooms-vertical'">
    <div class="meeting-session-grid-component" v-bind:style="sessionGridComponent(containerWidth)"> 
    <div class=" meeting-corner-item "  v-bind:style="cornerItem(disableChange, isIos)" v-if="!syncActive"></div>
      <div class="meeting-room-items">
        <div class="meeting-room-item"></div>
        <div v-for="item in this.daysKeys" :key="item">
        <a class="meeting-room-item" style="color: #000; text-decoration: none">{{item}}</a>
        </div>
      </div>
      <div class="meeting-hours-grid" >
        
        <div class="meeting-hour-items-row">
          <div class="meeting-hour-header-item" v-for="hour in hours" :key="hour">{{hour}}:00</div>
        </div>
        <div v-for="item in this.daysKeys " :key="item" class="meeting-hour-items-row">
          <div v-for="hour in hours" :key="hour" class="meeting-hour-item" v-bind:style="dayColor(item)"></div>
         <template v-for="slot in appointments" :key="slot"> 
            <template v-if="slot.start_at.slice(0,10) == item ">

           <a class="meeting-session-item" v-bind:style="sessionPosition(slot)">  
              <div class="meeting-si-content" @click="activatedDay(slot.id)"   v-bind:style="siContent(slot.start_at, slot.end_at)">  <div style="padding-left: 5px">  {{slot.subject}} </div>

              <template v-if="checkRunningHour(slot)">
                <router-link v-if="slot.id == activeDaySlot" :to="'/app/videoroom/'+slot.id" class="meeting-link" v-bind:style="siButton(slot.start_at, slot.end_at)"> Go <i class="icon ion-play"></i> </router-link>
              </template>
              <template v-if="!checkRunningHour(slot)">
                <div  v-if="slot.id == activeDaySlot"  class="meeting-link" v-bind:style="siButton(slot.start_at, slot.end_at)"> Not current  </div>
              </template>

                <div class="meeting-si-time">{{(moment(slot.start_at).utc(false).format("HH:mm"))}} - {{moment(slot.end_at).utc(false).format("HH:mm")}}</div> 
                <div class="meeting-si-title">{{ slot.description}}</div>
                <div class="meeting-si-title">{{ slot.requester_user_email}}</div>
                <br>
                <div style="padding-left: 5px"> <b> Requester: </b> <br> </div>
                <div class="meeting-si-title">{{ slot.requester_user_email}}</div>
              </div>
            </a>
        
            </template>
          </template>

        </div> <!-- changed break to breakUnit (break is reserved) -->
       
      </div>
      <div class="filler"></div>
    </div>
  </div>
    </ion-content>
  </base-layout>
</template>

<script>
import {defineComponent} from 'vue';
import {menuController} from "@ionic/vue";
import {mapActions, mapGetters, mapState} from 'vuex';
import moment from 'moment';
import config from '../../config'
import { isPlatform } from '@ionic/vue';
const CONFERENCE_ID = config.conference;
import axios from 'axios';
const API_URL = config.appointment_api_url;



export default defineComponent({
  name: 'ConsultantMeetings',
  data() {
    return {
      isConsultant: false,
      activeDaySlot: null,
      daysKeys:[],

      //APPOINTMENTS AND REQUESTS
      consultantsData: null,
      userExhibirtor: null,
      authUserData: null,
      exhibitorsArray: null,
      consultantsIdArray: [],
      appointmentsInfo: {},
      mappedConsultants:[],

      //appointmentsListRequester: [],
      conferenceId : config.conference,
      user: null,
      noAppointments: false,
      minAppointment: false,

      //SCHEDULE AND SESSIONS
      sessions: null,
      days: {},
      activeDay : '',
      
      //GRID VARIABLES
      disableChange: true, 
      isIos: null,
      widthMultiplier: 4,//2.3333333333333333333333, //60 * 2.3 = 140px
      heightMultiplier: 3, //60 * 1 = 60px
      hours: [],
      day: moment().date(), //check this later
      roomsOnHorizontal: true,
      format: '',
      gridDays: [],

      //GRID VARIABLES TO BE SET ONCE WE GOT THE SESSION GRID DATA.
      rooms: null,
      breaks: null,
      dayStart: null,
      dayEnd: null,
      blockSize: null,
      containerWidth: null,
      range: null,
    }
  },
  computed: {
    ...mapState('sync', ['updateFinished','syncActive']),
     ...mapState('auth', ['user']),


    ...mapGetters('appointment',['appointmentsByRequester', 'appointments', 'appointmentRequests']),
    ...mapGetters('consultant',['consultantsByExhibitor']),

   
    
  },
  methods: {
    ...mapActions('appointment', ['getAppointmentsByRequester']),
    ...mapActions('sessions', ['getDays']),
    ...mapActions('auth', ['GET_USERDATA']),
    ...mapActions('consultant',['getConsultantsByExhibitor']),
    ...mapActions('appointment',['getAppointments', 'getRequests']),
    ...mapActions('exhibitors', ['getAllExhibitors']),
    
    openMenu() {
      menuController.open("app-menu")
    },

    sessionGridComponent(containerWidth){
     return{
     'width': containerWidth + 'px',
     };
   },


    checkRunningHour(slot){
      
      let now = moment().utc(true).toISOString()
      let start = moment(slot.start_at).utc(false).toISOString()
      let end = moment(slot.end_at).utc(false).toISOString()
     if(moment(now).isBetween(moment(start), moment(end), 'minute')){

      console.log("TRUE")
      return true;
     } else {
       return false;
     }
    },

    siContent(start, end){
      let slot = {}
      slot.start_at = start
      slot.end_at = end
     if( this.checkRunningHour(slot)){
      return{
      'border-left-color': 'green',
      };
     } else {
        return{
        'border-left-color': 'red',
        };
     }
   },

    siButton(start, end){
      let slot = {}
      slot.start_at = start
      slot.end_at = end
     if( this.checkRunningHour(slot)){
      return{
      'background': 'green',
      };
     } else {
        return{
        'background': 'red',
        };
     }
   },
    
    setActiveDayForToday(){ 

      for(let day in this.days){
        if (day==moment().format('YYYY-MM-DD')){ 
        this.activeDay = day;
        }
      }

      if(this.activeDay!=null){
        let daysArray=[];
          for(let d in this.days){
            daysArray.push(d);
          }
          this.activeDay=daysArray[0]

          }
        
    },

     /* cornerItem(disableChange, isIos){
      if(disableChange && isIos){
        return {
        'top': '43px !important',
              };
      } 
    },*/
    cornerItem(disableChange, isIos){
      if(disableChange){
        return {
          'top':'50px !important',
              };
      } else if(isIos){
        return {
        'top': '50px !important',
        }
      } else {
        return {
        'top': '50px !important',
        }
      }
    },

    pStyle(blockSize){
    return{
    'width': blockSize +'px !important',
    }
  },

 
   
   siCode(session){
     let color = session.color;
     let fontColor = session.font_color;

     return{
     'background-color': color,
     'color': fontColor,
     };
   },

     rotateView(){ 
      this.roomsOnHorizontal = !this.roomsOnHorizontal; //swap true to false 
      return true;
    },

    setGridData(){
      this.hours = []; //reset it on change, will have to check more variables to reset
     
      this.dayStart = this.userConsultant[0].available_from.slice(0,2);
      this.dayEnd = this.userConsultant[0].available_until.slice(0,2);
      for (let i = this.dayStart; i <= this.dayEnd; i++) {
          this.hours.push(i); //set the number of hours based on the difference between start and end
        }
      let parsedDays = Object.entries(this.days).map(e => e[1])
      this.containerWidth = parsedDays.length * 140;
    },


    activatedDay(slotId) {
      if(this.activeDaySlot == slotId){
        this.activeDaySlot = null;
          }else{
          this.activeDaySlot = slotId;
       }
      },

    sessionPosition(item) {
      let startTmp = item.start_at.slice(11,19);
      let startTmpHours = parseInt(startTmp.split(':')[0])
      let startTmpMinutes = parseInt(startTmp.split(':')[1])
      let endTmp = item.end_at.slice(11,19);
      let endTmpHours = parseInt(endTmp.split(':')[0])
      let endTmpMinutes = parseInt(endTmp.split(':')[1])

      let startTime = startTmpHours;
      let endTime = endTmpHours;
      //startTime = new Date(item.start_time);
      //endTime = new Date(item.end_time);
      let dimension = this.roomsOnHorizontal ? 'height' : 'width';
      let translate = this.roomsOnHorizontal ? 'translateY(' : 'translateX(';
      let pixelMultiplier = this.roomsOnHorizontal ? this.heightMultiplier : this.widthMultiplier;
    
      let duration = moment.duration(moment(item.end_at).diff(moment(item.start_at)));

      if(item.id==this.activeDaySlot && duration.asMinutes()<60){
      return {
        [dimension]: ((endTime - startTime) * 60 + endTmpMinutes - startTmpMinutes) * pixelMultiplier + 'px',
        'transform': translate + (((startTime - this.dayStart) * 60 + startTmpMinutes)) * pixelMultiplier + 'px)',
        'height':  '200px !important',
          'z-index': '30',
          'opacity': '0.95',
      };
      }else{
        return{
           [dimension]: ((endTime - startTime) * 60 + endTmpMinutes - startTmpMinutes) * pixelMultiplier + 'px',
          'transform': translate + (((startTime - this.dayStart) * 60 + startTmpMinutes)) * pixelMultiplier + 'px)',
        }
      }
    },


    showRequests(){
      this.isRequests = true;
    },

      dayColor(day){
        let colorsArray = ['#B9D2ED','#B3DFF2','#ADD8DB','#B3F2E7','#ACE8CE']; //5 colors
        let dayNumber = this.daysKeys.indexOf(day);
        while(dayNumber>4){ //infinite loop to get numbers always from 0 to 4
          dayNumber = dayNumber-5;
        }
      return{
     'background-color': colorsArray[dayNumber],
     };
    },

    async prepareGrid(){

      //this.userExhibitor = 
      //await this.getConsultantsByExhibitor(this.userExhibitor[0].id);

    let consultantById = (await axios.post(API_URL+"consultants/getconsultantbyauthuser/"+this.user.data.id)).data;
    this.consultantsIdArray[0] = consultantById[0].id

  this.appointmentsInfo = {'ConsultantArray': this.consultantsIdArray, 'Conference': CONFERENCE_ID, 'Accepted': true }; 
  await this.getAppointments(this.appointmentsInfo)
  this.requestsInfo = {'ConsultantArray': this.consultantsIdArray, 'Conference': CONFERENCE_ID}; 
  await this.getRequests(this.requestsInfo)

  this.setGridData();

    },

    

  },


  async created() {      
   this.isIos= isPlatform('ios')  
   this.user = await this.$store.dispatch('auth/GET_USERDATA');
   await this.getAppointmentsByRequester({"ConferenceId":this.conferenceId,"UserId":this.user.data.id}); 
   this.moment = moment;
   this.days = await this.getDays();
   this.daysKeys = Object.keys(this.days)
   this.setActiveDayForToday();

   this.exhibitorsArray =  await this.getAllExhibitors()
  //this.exhibitorsArray= (await axios.get("https://mantel-api.mobile.documedias.systems/v2/conferences/"+CONFERENCE_ID+"/exhibitors")).data //get all exs of conference

  this.userExhibitor = this.exhibitorsArray.filter(ex=>ex.email == this.user.data.emailBusiness) 
  //AÑADIR CONTROL POR SI EL USUARIO NO ES MIEMBRO DE EXHIBITOR
  
  if(this.user){
  
      this.userConsultant = (await axios.post(API_URL + "consultants/getconsultantbyauthuser/"+this.user.data.id)).data; //gets consultant from user
    
       this.exhibitorsArray =  await this.getAllExhibitors()//get all exs of conference
      if(this.exhibitorsArray){ //if there is exhibitors
        let exhibitorFromThisConference = this.exhibitorsArray.filter(ex=>ex.id == this.userConsultant[0].exhibitor_id);  //looks if the consultant exhibitor is in the exhibitor list of this confernece
     
        if(exhibitorFromThisConference.length>0){
         
          if(this.userConsultant){
            this.isConsultant = true
          } else {
            this.isConsultant = false;
             
          }
        }
      }
      this.prepareGrid();
    }
 
  },

});
</script>
<style lang="scss">

</style>